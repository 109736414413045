<template>
    <div>
        <vpsHeader></vpsHeader>
        <div class="suspend-error-container">
            <img class="vps-email-image" src="@/common/assets/styles/corp/images/vps_mail.png" />
            <p>Your User ID is sent to <b>{{ emailSentTo }}</b></p>
            <p>Please continue to Sign-in.</p>
            <button type="submit" v-on:click="continueToLogin()" class="btn vbg-ff-btn-continue spacing05 vps-cont-btn">
                Continue
            </button>
        </div>
        <vpsFooter></vpsFooter>
    </div>
</template>

<script>
import vpsHeader from '@/vbgauth/components/vpsHeader'
import vpsFooter from '@/vbgauth/components/vpsFooter'
import { mapGetters, mapActions } from 'vuex'
export default {
    name: "VPSForgotUserIdEmailSent",
    components: {
        vpsHeader,
        vpsFooter
    },
    data() {
        return {
            emailSentTo: ""
        };
    },
    mounted() {
        this.emailSentTo = this.maskEmailAddress(this.vpsSuccessPageEmail);
    },
    computed: {
        ...mapGetters('cvsstepup', [
            'vpsSuccessPageEmail'
        ]),
        ...mapGetters('login', [
            'getVPSUnifiedFlow'
        ])
    },
    methods: {
        continueToLogin() {
            if(this.getVPSUnifiedFlow) {
                this.$router.push({ name: 'VPSPwdLessLoginRootComponent' });
            } else {
                this.$router.push({ name: 'PwdLessLoginRootComponent' });
            }
        },
        maskEmailAddress(value) {
            let emailparts = value.split('@');
            let firstLetter = emailparts[0].charAt(0);
            let lastletter = emailparts[0].charAt(emailparts[0].length - 1);
            let maskedpart = 'x'.repeat(emailparts[0].length - 2);
            return firstLetter + maskedpart + lastletter + '@' + emailparts[1];
        },
    },
    watch: {
        vpsSuccessPageEmail: function (newVal) {
            if (newVal === 'emailSentSuccess') {
                this.$router.push({ name: 'VPSForgotUserIdEmailSent' });
            }
        },
    }
};
</script>
<style scoped>
.vps-email-image {
    height: 50px;
    width: 50px;
    margin-top: 100px;
    margin-bottom: 25px;
}

.vps-cont-btn {
    margin-top: 30px;
}

@media screen and (max-width: 700px) {
    .vps-cont-btn {
        width: 100% !important;
    }
}

.suspend-error-container {
    width: 100%;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 280px;
    word-break: break-word;
}

.err-head-pos {
    margin-bottom: 30px;
}
</style>