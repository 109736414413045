<template>
  <div>
    <!-- <div>{{ this.isValidEmail }}</div> -->
    <v-form @submit.prevent="submit" ref="fuForm">
      <!-- <h1 class="new_pwdless_main_heading pwdles_main_heading">
        Forgot User ID
      </h1> -->
      <div class="new_pwdless_input_label">Email address</div>
      <div class="vbg-icon-input-group">
      <input 
        id="email"
        v-model="email"
        type="text"
        @keydown.space.prevent
        @input="trimInput(email)"
        class="new_pwdless_input_field" 
        :class="!isValidEmail? 'orange_input': ''" 
        style="margin-bottom: 0px;"
        :autocomplete="formInputs[2].autocomplete"
        name="email"
      />
      <!-- <div 
        class="vbg-warning-icon" 
        v-if="!isValidEmail"
      ><img :src="warningIcon" /></div> -->
      <div v-if="!isValidEmail">
        <p class="pmicroEmail" 
          v-html="$t('message.forgot_username_emailAdress_error')"
        >
        </p>
      </div>
      </div>
      <div v-if="this.jCaptchaFlag">
        <div>
          <div class="captcha-blk">
            <h2 style="margin-bottom: 6px; font-size: 20px;" v-if="isFallbackJcaptchaEnabled">Additional security check</h2>
            <div class="captcha-txt-blk">
              <img
                id="captchaImg"
                src=""
                :class="[
                  getOAuthClass('oauth-flow-jcaptch'),
                  'captcha_img_fu',
                ]"
              /><br />
            </div>
          </div>
          <button
            type="button"
            class="captcha_spacing new_pwdless_forgot_text_link"
            v-on:click="refreshCaptchaImgUrl()"
            name="refreshCaptcha"
            id="refreshCaptcha"
          >
            Refresh Image
          </button>
        </div>
        <div>
          <label class="new_pwdless_input_label" for="captchaInput"
            >Type the characters from the above image</label
          >
          <input
            type="text"
            id="captchaInput"
            name="captchaInput"
            @keydown.space.prevent
            class="new_pwdless_input_field"
            v-model="captchaText"
          />
        </div>
      </div>
      <invisible-recaptcha :disabled="!email|| !isEmailValid() || !captchaText" v-if="this.captchaOn" :sitekey="forgotUsrnCaptchaKey" theme="light" badge="bottomright" :callback="onCaptchaVerified" :class="formInputs[3].class" :style="formInputs[3].style" :type="formInputs[3].type">{{ formInputs[3].value }}</invisible-recaptcha>
      <div class="pwd_less_btn_container submit_btn_spacing">
        <div class="new_pwdless_btn_section">
          <button 
            v-if="!this.captchaOn" 
            :disabled="isDisabled()" 
            @click="OnFormSubmit()" 
            :class="isDisabledClass()"
            :style="formInputs[3].style" 
            :type="formInputs[3].type"
            class="new_pwdless_submit_btn"
            id="username-continue-btn"
            name="username-continue-btn"
          >
            {{ formInputs[3].value }}
          </button>
        </div>
        <div class="new_pwdless_btn_section new_pwdless_btn_section_mobile">
          <button 
            class="new_pwdless_submit_btn pwd_cancel_btn"
            @click="cancelUsrRecovery()" 
            :style="formInputs[4].style"
            type="button"
            id="username-cancel-btn"
            name="username-cancel-btn"
          >{{ formInputs[4].value }}</button>
        </div>
      </div>
    </v-form>

  </div>
</template>
<script>
import InvisibleRecaptcha from 'vue-invisible-recaptcha'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import caret_blk from "@/common/assets/styles/corp/images/CTA-caret_blk.png";
import {eventTracking} from '../../../../../public/adobeTagging'
import warningIcon from '@/common/assets/styles/corp/images/warning.png'
import commonMixin from "@/common/mixin/commonMixins";
export default {
  name: 'NewAMForgotUsernameUserDetailsComponent',
  components: {
    "invisible-recaptcha": InvisibleRecaptcha
  },
  mixins: [commonMixin],
  data() {
    return {
      otpEnabled: true,
      contact_admin: iamvar_contact_admin,
      caret: caret_blk,
      valid: false,
      fieldRules: [v => !!v || 'Please fill out this field'],
      fname: null,
      lname: null,
      email: null,
      captchaOn: true,
      captchaText: null,
      jCaptchaFlag: true,
      submittedAlready: false,
      whichRealm: true,
      emailFormatRegex: /^[a-zA-Z0-9](\.?\_?\-?[a-zA-Z0-9]){0,}@[a-zA-Z0-9-_]+\.([a-zA-Z0-9-_]{1,}\.){0,}[a-zA-Z]{2,}$/,
      userValidationInputClass: '',
      warningIcon: warningIcon,
      isValidEmail: true,
      loaderGIF: iamvar_cdnUrl + iamvar_imgSourceContext + '/business/login/includes/circle-loaderWHITE_48x48.gif',
      isFallbackJcaptchaEnabled: false,
      recaptchasiteKey: iam_recaptchaV3_sitekey,
      isrecaptchaEnabled:  iamvar_enable_recaptchaV3_ForgotFlows
    }
  },
  beforeMount () {
    if(this.isrecaptchaEnabled){
      this.addRecaptchaScript();
    }
  },
  mounted() {
    this.changeContextBiocatch('forgot_username_email_page')
    this.isrecaptchaEnabled = iamvar_enable_recaptchaV3_ForgotFlows
    if(this.isrecaptchaEnabled){
      this.captchaOn = false;
      this.jCaptchaFlag = false;
      this.isFallbackJcaptchaEnabled = false;
    }
    this.resetRecaptchaV3State();
    //get updated captcha on page load
    this.$nextTick(() => this.refreshCaptchaImgUrl())
    if (window.location.href.includes('business')) {
      this.readConfig('vbg_config')
    } else if (window.location.href.includes('consumer')) {
      this.readConfig('vcg_config')
    }
    if ((this.urlOnMount != null) || (this.urlOnMount != undefined)) {
      const hostUrl = this.urlOnMount
      if (hostUrl.includes('/account/business/login/mobile')) {
        this.whichRealm = false;
      }
    }
    if (iamvar_realm == 'MOBILE') {
      let returnUrl = this.urlOnMount;
      this.updateUrlOnMount(returnUrl);
    }
    if (document.cookie.indexOf('X-VZ-C_APP') !== -1) {
      let iamvar_appCookie = getCookie('X-VZ-C_APP')
      if (iamvar_appCookie == 'MVB') {
        this.whichRealm = false;
      }
    }

    this.readFormInputs('user_details_input_component')

    this.captchaOn = iamvar_enableCaptcha;
    if(!this.isrecaptchaEnabled){
      this.jCaptchaFlag = iamvar_enableJCaptcha;
    }
  },
  beforeDestroy() {
    if(this.isrecaptchaEnabled){
      this.removeRecaptchaScript();
    }
  },
  methods: {
    ...mapActions('cvsstepup', [
      'updateUserDetailsForStepup',
      'updateFlowType',
      'initializeStepup',
      'updateRecaptchaResponse',
      'updateCaptchaText',
      'igCaptchaEndpoint',
      'updateClearInputFields'
    ]
    ),
    ...mapActions('forgotflow', [
      'readConfig',
      'readFormInputs',
      'checkCaptchaScoreIG',
      'resetRecaptchaV3State'
    ]
    ),
    ...mapActions('login', [
      'updateUrlOnMount'
    ]),
    ...mapMutations("login", ["setCommonErrors"]),
    isEmailValid() {
      return (this.email == "") ? "" : (this.emailFormatRegex.test(this.email)) ? true : false;
    },
    OnFormSubmit: function () {
      if(this.isrecaptchaEnabled && !this.isFallbackJcaptchaEnabled) {
        this.resetRecaptchaV3State();
        this.checkRecaptchaScore();
      } else {
        this.onCaptchaVerified('');
      }
    },
    onCaptchaVerified(recaptchaToken) {
      this.setCommonErrors()
      this.updateUserDetailsForStepup({
        "email": this.email.trim()
      })
      eventTracking('Username Recovery Request Initiated', '');
      this.updateFlowType('forgot_username')
      // push to stepupRootComponent only if the username is valid (returned by the backend)
      this.emaiIDValidation(this.email)
      this.updateRecaptchaResponse(recaptchaToken)
      if(this.isrecaptchaEnabled && !this.isFallbackJcaptchaEnabled){
          // this.initializeStepup({ challengeMethod: "SQA" });
           this.igCaptchaEndpoint({ challengeMethod: "", isrecaptchaEnabled: true});
        }else {
          this.updateCaptchaText(this.captchaText.trim());
          this.igCaptchaEndpoint({ challengeMethod: "", isrecaptchaEnabled: false});
        }
      //this.refreshCaptchaImgUrl()
      //this.refreshCaptchaImgUrl()
      //this.captchaText = null;
    },
    cancelUsrRecovery() {
      this.$store.dispatch('cvsstepup/resetState')
      this.$store.dispatch('forgotflow/resetState')
      var returnUrl = this.getHostName(window.location.href);

      if (document.cookie.indexOf('X-VZ-C_APP') !== -1) {
        let iamvar_appCookie = getCookie('X-VZ-C_APP')
        if (iamvar_appCookie == 'MVB') {
          window.location.href = iamvar_mvbLaunchUrl;
        }
      } else if (!this.urlOnMount) {
        window.location.href = this.sanitizeUrl("https://" + returnUrl + "/account/business/login/unifiedlogin");
      } else {
        window.location.href = this.urlOnMount;
      }

      // checking if user agent is vema then redirect user back to mobile login page
      if (this.userAgent && this.userAgent.toLowerCase() === 'vema') {
        window.location.href = iamvar_vemaLaunchUrl;
      }
    },
    getHostName(url) {
      var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
      if (match != null && match.length > 2 && typeof match[2] === 'string' && match[2].length > 0) {
        return match[2];
      }
      else {
        return null;
      }
    },
    //getCaptchaImgUrl() {
    //  var captchaImgHref = this.getHostName(window.location.href);
    //  let srcURL = this.sanitizeUrl("https://" + captchaImgHref + "/ifgservices/vbg/captcha");
    //  return srcURL;
    //},
    refreshCaptchaImgUrl() {
      if (!this.isrecaptchaEnabled) {
      var captchaImgHref = this.getHostName(window.location.href);
      document.getElementById("captchaImg").src = this.sanitizeUrl("https://" + captchaImgHref + "/ifgservices/vbg/captcha?cb=" + Math.random());
      }
    },
    getOAuthClass(className = 'oauth-flow') {
      return className
    },
    emaiIDValidation(newVal) {
      if (newVal) {
        if (!this.emailFormatRegex.test(newVal)) {
          this.userValidationInputClass = "vz-error";
        } else {
          this.userValidationInputClass = "";
        }
      }
    },
    isDisabled() {
      if (
        !this.formComplete
        || (!this.captchaText && this.jCaptchaFlag)
        || !this.isValidEmail || this.submitLoader
      ) {
        return true;
      }
      return false
    },
    isDisabledClass() {
      if (
        !this.formComplete
        || (!this.captchaText && this.jCaptchaFlag)
        || !this.isValidEmail || this.submitLoader
      ) {
        return ' disabled';
      } else {
        return "";
      }
    },
    trimInput() {
      this.email = this.email.trim();
    },
    checkRecaptchaScore() {
      try {
        // on click recaptcha token is generated and passed to IG API for getting score.
        grecaptcha.enterprise.ready(() => {
          grecaptcha.enterprise.execute(this.recaptchasiteKey, { action: 'submit_forgotUsername' }).then((token) => {
            let payloadData = {
              token: token,
              flowType: 'forgot_username',
              userData: this.email.trim()
            }
            this.checkCaptchaScoreIG(payloadData);
            // On IG API call success (meaning good score) leave isFallbackJcaptchaEnabled as false default if else enable that flag
          }).catch((error) => {
            console.log("Error occured in recaptcha", error);
            this.isFallbackJcaptchaEnabled = true;
          })

        });
      } catch (error) {
        console.log("Error occured in recaptcha", error);
        this.isFallbackJcaptchaEnabled = true;
      }

    },

    // add recaptcha scripts to DOM on component load. isFallbackJcaptchaEnabled flag is used and set to true when script load fails 
    addRecaptchaScript() {
      console.log("Adding recaptcha script inprogress")
      try {
        const addscript = document.createElement("script");
        addscript.id = "recaptcha-version-3-vbgui";
        addscript.src = `https://www.google.com/recaptcha/enterprise.js?render=${this.recaptchasiteKey}`;
        addscript.async = true;
        addscript.defer = true;
        document.head.appendChild(addscript);
        console.log("Adding recaptcha script success")
      } catch (error) {
        console.log("Error occured in recaptcha", error);
        console.log("Adding recaptcha script failed")
        this.isFallbackJcaptchaEnabled = true;
      }

    },

    // remove recaptcha scripts on page unload from the DOM
    removeRecaptchaScript() {
      const removeScript = document.getElementById("recaptcha-version-3-vbgui");
      if (removeScript) {
        removeScript.parentNode.removeChild(removeScript);
      }
      if(window && window.grecaptcha) {
        delete window.grecaptcha;
      }
      let badge = document.querySelector('.grecaptcha-badge');
      if(badge && badge.parentNode) {
        badge.parentNode.removeChild(badge);
      }
    },
  },
  computed: {
    ...mapGetters('forgotflow', [
      'forgotUsrnCaptchaKey',
      'forgotFlConfig',
      'formInputs',
      'iamvar_enableCaptcha',
      'recaptchaV3State'
    ]),
    ...mapGetters('cvsstepup', [
      'currentStep',
      'cannotRecoverUsername',
      'urlOnMount',
      'fuAlerts',
      'submitLoader',
      'clearInputFields'
    ]),
    ...mapGetters("login", ["mybizzFlag", "userAgent"]),
    formComplete() {
      return this.email
    }
  },
  watch: {
    email(newVal) {
      if (newVal) {
        if (!this.emailFormatRegex.test(newVal)) {
          this.isValidEmail = false
          return
        }
      }
      this.isValidEmail = true;
      return;
    },

    currentStep: function (newVal) {
      if (newVal === 'FLOW_INITIALIZED' && !this.cannotRecoverUsername) {
        this.$router.push({ name: 'FFStepupRootComponent' })
      }
    },
    fuAlerts: function (newVal) {
      if (newVal) {
        //console.log("newVal..",newVal);
        this.refreshCaptchaImgUrl();
        this.captchaText = null;
        this.submittedAlready = false;
        window.scrollTo(0, 0);
      }
    },
    clearInputFields: function (newVal) {
      console.log("watch value", newVal)
      if (newVal) {
        this.email = ''
        this.captchaText = null
        this.updateClearInputFields(!newVal)
      }
    },
    recaptchaV3State: function (newVal) {
      // when validation is passed, flow will move a head as per BAU
      if(newVal === "Validation_Passed"){
        this.onCaptchaVerified('');
      } else if (newVal === "Validation_Failed") {  // when validation is failed fallback J captcha is enabled.
        this.isFallbackJcaptchaEnabled = true;
      }
    },
    isFallbackJcaptchaEnabled: function (newVal) {
      if(newVal == true){
        this.jCaptchaFlag = true;
        this.isrecaptchaEnabled = false;
        this.$nextTick(() => this.refreshCaptchaImgUrl());
      }
    },
  }
}
</script>
<style>
.grecaptcha-badge {
  bottom: 150px !important;
}
</style>
<style scoped>
.pwdles_main_heading {
  margin-bottom: 24px;
}

.pwdles_sub_heading {
  margin-bottom: 32px;
}

.new_pwdless_btn_section {
  margin-top: 16px;
  width: 186px;
}
.vbg-icon-input-group {
  margin-bottom: 16px;
}
.orange_input,
.orange_input:focus {
  border: 1px solid #B95319 !important;
  background-color: #FFECE0;
}
</style>
