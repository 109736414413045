<template>
<div style="overflow: hidden; font-family:'VzNHGeDS-reg11'">
<div id="vz-gh20"></div>
<div v-if="getUnifiedLoginAlerts.length > 0" class="marbottom20">
  <div class="row bannerMarginUnset" :class="bannerContainer()" v-for="j in getUnifiedLoginAlerts" :key="j">
    <div class="col-sm-12 newMessagesInfo" v-bind:class="getTypeOfAlert(j)" style="padding:0px;">
      <div v-if="j.type== 'information' || j.type== 'success'" class="newMessagesInfo" v-bind:class="getTypeOfAlert(j)" style="max-width:1271px">
        <div class="messageWrapper">
          <div class="messageIcon" style="padding:0px;margin:0px">
            <div class="messageImage" :class="messageImage(j)"></div>
          </div>
            <p class="messageContent col-xs-9" style="padding:0px;margin:0px;color:#fff;" v-html="j.msg"></p>
          <div class="closeMessage col-xs-1" style="padding:0px;margin:0px">
            <img class="closeMessageImg successbannercloseimg" :src="getCloseIcon()" @click="deleteAlert(j)" />
          </div>
        </div>
      </div>
      <div v-else class="newMessagesInfo" v-bind:class="getTypeOfAlert(j)" style="max-width:1271px">
        <div class="messageWrapper">
          <div class="messageIcon" style="padding:0px;margin:0px">
            <div class="messageImage" :class="messageImage(j)"></div>
          </div>
            <p class="messageContent col-xs-9" style="padding:0px;margin:0px;color:#000000" v-html="j.msg"></p>
            <div class="helpblock" v-if="getHelpCheck">
              <button class="helpbutton" @click="gethpop('gethelp')">
                <span class="buttontext"> Get Help</span>
              </button>
            </div>
          <div class="closeMessage col-xs-1" style="padding:0px;margin:0px">
            <img class="closeMessageImg errorbannercloseimg" src="@/common/assets/styles/corp/images/close_black.png" @click="deleteAlert(j)" />
          </div>
        </div>
      </div>
      
    </div>
  </div>
</div>
  <div class="container martop20">
    <!-- <div class="vz-wrapper"> -->
      <div class="col-sm-1"></div>
      <div class="col-sm-8 col-md-4 container-to-your-business-acc loginmar20" style="padding: 2px;">
        <h2 class="log-in">Log in</h2>
        <p class="to-your-business-acc">to your business account</p>

        <input
          type="radio"
          v-model="meth"
          class="tab"
          name="meth"
          value="pass"
          @change="onTabChange($event)"
          id="usePass"
        />
        <label for="usePass">By Password</label>
        <input
          type="radio"
          v-model="meth"
          class="tab"
          name="meth"
          value="push"
          @change="onTabChange($event)"
          id="usePush"
        />
        <label for="usePush">By Device</label>
        <UsePassword v-if="meth == 'pass'" />
        <Usedevice v-if="meth == 'push'" />
      </div>
      <div class="col-sm-1"></div>
      <div class="col-sm-8 col-md-4">
        <div id="promo1" class="default-promo1">
          <p class="default-promo">Manage your business account online anytime, anywhere.</p>
        </div> 
        <!-- <div class="promos">
          <div id="promo-1">
           
          </div>
          <div id="promo-2">
           
          </div>
        </div> -->
      </div>
      <div class="col-sm-1"></div>
    <!-- </div> -->
  </div>
	<div id="vz-gf20"></div>
</div>
</template>

<script>
import UsePassword from "./UsePassword";
import Usedevice from "./Usedevice";
import $ from "jquery";
import { mapActions, mapGetters } from 'vuex';
import {setPageDetails} from '../../../../public/adobeTagging';
import ClearWhite from "@/common/assets/styles/corp/images/clear_white.png";
import CloseBlack from "@/common/assets/styles/corp/images/close_black.png";
import translations from "@/common/assets/json/messages1.json";
import commonMixin from '@/common/mixin/commonMixins'

export default {
  name: "UnifiedLogin",
  components: { Usedevice, UsePassword },
  mixins: [commonMixin],
  data() {
    return {
      meth: "pass",
      showClickHereMsg: false,
      idmRedirect: ''
    };
  },
  computed:{
    ...mapGetters('login',[
        "getUnifiedLoginAlerts","getUnifiedErrorCode"
    ]),
    getHelpCheck(){
      return (this.getUnifiedErrorCode == "ACCOUNT_LOCK_PREVENTION" || this.getUnifiedErrorCode == "ACCOUNT_LOCKED" || this.getUnifiedErrorCode == "ACCOUNT_PREVENTION" || this.getUnifiedErrorCode == "ACCOUNT_LOGIN_LOCKED" || this.getUnifiedErrorCode == "OTP_COUNTER_AND_PROFILE_INVALID_ATTEMPTS_COUNTE_MISMATCH") ? true : false
    }
  },
  watch : {
    getUnifiedLoginAlerts: function() {
      if(this.getUnifiedLoginAlerts.length > 0) {
        window.scrollTo(0,0);
      }
    }
  },
  async created() {
       setPageDetails('UnifiedLoginMain:landing');
  },
  mounted(){
    var globalNav = document.createElement('script')
      globalNav.setAttribute('src', 'https://www.verizon.com/business/ngnav/smb.js')
      globalNav.setAttribute('async', 'async');
      document.head.appendChild(globalNav)
      // f.setAttribute('id',"login-form");
      // f.setAttribute('name',"loginForm");

      var rky = window.location.search.match(/Reg_Message=([^\&]+)/) || [0, 0];
      if (rky && rky[1]) {
        // document.getElementById("regMsgP").innerHTML = regMsg[rky[1]] || "";
        // document.getElementById("regMsgS").checked = true;
        this.updateSetUnifiedLoginAlerts(rky[1].toLowerCase())
      }
      if(this.getParameterByName('success') =='true' || this.getParameterByName('success') == true) {
        var alertMsg = translations.en.message["forgot_username_display"]
        var msg = [{msg: alertMsg.replace('$0', this.getParameterByName('uid')), type:'success'}]
        this.updateUnifiedLoginAlerts(msg)
      }
      window.localStorage.setItem("InitialRoute", window.location.href);
      //browser back button disabled
      window.onpopstate = () => {
        window.history.forward();
      };
  },
  methods: {
    ...mapActions('login',[
      'setCookieForBlueBanner','UpdatePopupValue','updateSetUnifiedLoginAlerts','updateUnifiedLoginAlerts'
    ]),
    getCloseIcon(){
      // return  (
      //   iamvar_cdnUrl +
      //   iamvar_imgSourceContext +
      //   "/business/login/includes/img/close.7e170800.svg"
      // )
      //return this.getUnifiedLoginAlerts[0].type == "success"
      // ? CloseBlack : ClearWhite;
      return ClearWhite;
    },
    bannerContainer() {
      return (this.getUnifiedLoginAlerts[0].type == 'success' || this.getUnifiedLoginAlerts[0].msg.includes("Too many failed attempts")) ? 'bannerMargin' : ''
    },
    
    onTabChange(event) {
      this.meth = event.target.value;
    },
    deleteAlert(alert) {
      this.getUnifiedLoginAlerts.splice(this.getUnifiedLoginAlerts.indexOf(alert),1)
    },
    getMsgIcon(index) {
      // return iamvar_cdnUrl+'account/business/login/includes/img/info.png'
      return "@/common/assets/styles/corp/images/"+index.type+".png"
    },
    messageImage(index) {
      return index.type+"MessageImage"
    },
    getTypeOfAlert (index) {
          return index.type
    },
    addRedirectUrlToErrorBanner() {
        /* if the current authError (banner showed on screen) is for the user being redirected to IDM 
            (errorCode = REDIRECT_TO_IDM_REQUIRED) we need to add text that says "click here", 
            with an href of the IDM url
        */

        // TODO: confirm if 'redirected' is a good key word to identify this error message
        let errorMsg = this.$store.getters['login/authError'];
        if(errorMsg.errorMessage.includes('redirected')) {
            this.showClickHereMsg = true;
        }

    },
    gethpop(value){
      this.UpdatePopupValue(value);
      $("#noAccessToOtpModal").modal({
        backdrop: "static",
      });
    }
  },
  async created() {
    gnavdl = {
      "bu": "smb",
      "appid" : "unified"
    }
        setPageDetails('unifiedLogin:landing');
        this.setCookieForBlueBanner()
       
  },
};
</script>
<style>
.bannerMarginUnset{
  margin:unset;
}
.messageContent{
  margin-left: 10px !important;
}
.successbannercloseimg{
  cursor: pointer;
  width: 13px;
  height: 13px;
}
.log-in {
  height: 48px;
  width: 386px;
  color: #000000;
  font-family: 'VzNHGeDS-bold11';
  font-size: 48px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 48px;
  margin-bottom: 7px;
}
.container-to-your-business-acc {
  width: 384px;
  max-width: 384px;
}
.to-your-business-acc {
  height: 24px;
  width: 386px;
  color: #000000;
  /* font-family: "Verizon NHG eDS"; */
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 24px;
}
/* hr.line-default {
  height: 1px;
  margin-top: 32px !important;
  margin-bottom: 24px !important;
} */
hr.vz-horizontal {
  margin: 24px 0 !important;
}
.user-id {
  height: 98px;
  /* width: 385px; */
  margin-bottom: 24px;
}
.-input-label {
  height: 16px;
  width: 45px;
  color: #000000;
  /* font-family: "Verizon NHG eTX"; */
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
}
.input-f {
  box-sizing: border-box;
  height: 44px;
  width: 100%;
  border: 1px solid #d8dada;
  background-color: #ffffff;
  margin-bottom: 14px;
  border-bottom: #000000 1px solid !important;
  padding: 6px 12px;
  font-size: 16px;
}
.forgot-link {
  height: 20px;
  color: #000000;
  /* font-family: "Verizon NHG DS"; */
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
  float: right;
  cursor: pointer;
  text-decoration: underline;
}
input[type="checkbox"].tc {
  width: 20px;
  height: 20px;
  outline: 1px solid #000;
  margin-right: 10px;
  position: relative;
  left: unset;
}
p > label {
  height: 20px;
  /* width: 296px; */
  color: #000000;
  /* font-family: "Verizon NHG eDS"; */
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
}
.vz-login-link {
  height: 20px;
  /* width: 385px; */
  color: #000000;
  /* font-family: "Verizon NHG DS"; */
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
  display: table;
}
a {
  color: #000000;
  text-decoration: underline;
}
a:hover {
  color: #000000;
  text-decoration: underline;
  cursor: pointer;
}

/* input#usePass:not(:checked) ~ form.signin {
  display: none;
}
form.pushNotification {
  display: none;
  position: relative;
}
input#usePush:checked ~ form.pushNotification {
  display: block;
} */

input.tab {
  appearance: none;
  -webkit-appearance: none;
  border: none;
  display: none;
  background: transparent;
  visibility: hidden;
}
input.tab {
  display: inline-block;
  position: absolute;
  margin-left: -6px;
  height: 40px;
  width: 120px;
  cursor: pointer;
}
/* input.tab+label { display:none; } */
input.tab + label {
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  vertical-align: bottom;
  padding: 6px 24px 15px 0;
  margin: 0 0 24px;
  border-bottom: solid 1px #ccc;
  font-size: 16px !important;
}
input.tab + label:not(:hover) {
  color: #777;
}
input#usePush + label {
  padding-left: 24px;
  padding-right: 0;
}
input.tab:checked + label {
  border-bottom: solid 4px #d52b1e;
  padding: 6px 0 12px !important;
  color: #000;
  font-family: VzNHGeDS-bold11;
}
/* .tab+label span { font-size: 12px; line-height: 20px; padding-left: 6px; font-weight:normal; position:absolute; } */

.vz-wireless-para {
  height: 60px;
  /* width: 385px; */
  color: #0c0c0c;
  /* font-family: "Verizon NHG eDS"; */
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 20px;
  margin-bottom: 28px;
}
.modal-content {
  position: relative;
  width: 560px;
  height: 640px;
  margin: 0 auto;
}
.modal-dialog {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}
.modal-content-pwl {
  height: 440px;
}
.modal-content-cwl {
  height: 587;
}
.modal-content-int {
  height: 300px;
}
.modal-content-hybrid {
  height: 350px;
}
.modal-content-cn {
  height: 277px;
}
.modal-content-forgot {
  height: 525px;
}
.modal-content-gethelp{
  height: 629px;
}
.vz-wrapper{
  width: 489px;
}

.default-promo1{
  background: #000000;
  height: 745px;
  width: 490px;
  align-items: center;
  justify-content: center;
  display: flex;
}
.promos{
  height: 746px;
}
#promo-1{
  background: #eaeaea;
  height: 349px;
  width: 490px;
}
#promo-2{
  margin-top: 48px;
  background: #eaeaea;
  height: 349px;
  width: 490px;
}
.helpblock{
  Width:170px;
  Height:28px;
  position: absolute;
  margin-left: 485px;
}
.helpbutton{
  Width:88px;
  Height:28px;
  border-radius:14px;
  border: solid #000000;
}
.buttontext{
  height: 16px;
  width: 56px;
  color: #000000;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  top:-1px;
  line-height: 16px;
  text-align: center;
}
.newMessagesInfo{
    margin: auto;
 }
 .default-promo{
  height: 128px;
  width: 268px;
  color: #FFFFFF;
  font-family: VzNHGeDS-bold11;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center;
 }
.bold75{
  font-family: VzNHGeDS-bold11;
}
.modal-content {
  padding: 40px !important;
}
.close-icon-container {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 14px;
}
.modal-dialog {
  margin: 0px !important;
}
@media only screen and (min-width: 320px) and (max-width: 1200px) {
  .default-promo {
    display: none;
  }
  .default-promo1 {
    display: none;
  }
  .modal-dialog {
    margin: 0px !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 425px) {
  .modal-content {
    width: 300px;
    height: 800px;
  }
  .modal-content-pwl {
    height: 440px;
  }
  .modal-content-forgot {
    height: 440px;
  }
  .modal-content-int {
  height: 380px;
}
  .modal-content-hybrid {
  height: 450px;
}
  .vz-wrapper{
    width: 100%;
    padding: 10px;
  }
  .modal-content-cn {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
}
@media only screen and (min-width: 320px) and (max-width: 660px) {
  .helpblock {
      position: relative;
      margin-left: 30px;
      margin-top: 15px;
      float: left;
    }
}
@media  (min-width: 360px) and ( max-width: 415px) {
  .default-promo1{
    width: 327px !important
  }
  #promo-1{
    width: 327px !important

  }
  #promo-2{
    width: 327px !important

  }
}
@media  (min-width: 416px) and ( max-width: 530px) {
  .default-promo1{
    width: 375px !important
  }
  #promo-1{
    width: 375px !important

  }
  #promo-2{
    width: 375px !important

  }
}
@media  (min-width: 768px) and ( max-width: 959px) {
  .default-promo1{
    margin-left: 58px !important;
  }
  #promo-1{
    margin-left: 58px !important;
  }
  #promo-2{
    margin-left: 58px !important;
  }
}
@media  (min-width: 320px) and ( max-width: 340px) {
  .default-promo1{
    width: 285px !important
  }
  #promo-1{
    width: 285px !important
  }
  #promo-2{
    width: 285px !important
  }
}
@media (max-width: 425px) {
  .bannerMargin {
     margin: 8px 16px 0px;
  }
} 
@media (min-width: 270px) and (max-width: 400px) {
  .container-to-your-business-acc {
    width: 100%;
  }
}
@media (max-width: 280px) {
  .closeMessageImg{
    position: absolute;
    
  }
  .messageWrapper{
    position: relative;
  }
  .default-promo1{
    width: 262px;
    margin-left: -14px;
  }
  #promo-1{
    width: 262px;
    margin-left: -14px;

  }
  #promo-2{
    width: 262px;
    margin-left: -14px;

  }
  
}
@media ( min-width: 768px ) {
  .errorbannercloseimg{
    width: 13px;
    height: 13px;
    margin-top: -12px;
    cursor: pointer;
  }
}
@media ( max-width: 320px ) {
  .errorbannercloseimg{
    width: 13px;
    height: 13px;
    cursor: pointer;
  }
}
@media (min-width: 375px) and (max-width: 720px) {
  .errorbannercloseimg{
    width: 13px;
    height: 13px;
    margin-top: -6px;
    cursor: pointer;
  }
}
@media (max-width: 320px) {
  /* .closeMessageImg{
    position: absolute;
    top: -140px;
  } */
  .messageWrapper{
    position: relative;
  }
  
}
/* @media (max-width:912px) {
  
  .default-promo1{
    width: 288px;
  }
  #promo-1{
    width: 288px;
  }
  #promo-2{
    width: 288px;
  }
} */
@media (width:768px){
  .container{
    display: contents;
  }
}
@media (max-width: 540px){
  .modal-dialog.modal-dialog-centered{
    height: 100% !important;
    margin: 0px !important;
    width: 100% !important;
}
  .modal-content{
    width: 100%;
    height: 100%;
  }
}
@media(min-width: 320px) and (max-width: 768px){
  .loginmar20{
    margin-top: -20px;
  }
}
@media(min-width: 768px) and (max-width: 769px){
  .loginmar20{
    margin-top: 32px;
  }
}
@media(min-width: 150px) {
  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
}
</style>
