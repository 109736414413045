<template>
  <div>
    <div v-if="showTokLink">
      <h1 class="new_pwdless_main_heading pwdles_main_heading">
        Check your
        {{
          selectedOtpDevice && selectedOtpDevice.deviceType == "EMAIL"
            ? "email"
            : "text message"
        }}
      </h1>

      <div class="new_pwdless_sub_heading pwdles_sub_heading">
        Click the link we sent to
        {{ selectedOtpDevice.deviceValue }} and select allow. Once complete,
        this page will refresh and you can continue here.
      </div>
    </div>
    <!-- <div class="new_pwdless_sub_heading pwdles_sub_resend_text">
                  Resend text message in 30 seconds (Attempt 0/3)
                </div> -->

    <form v-else @submit.prevent="validateOtpCode(false)">
      <h1 class="new_pwdless_main_heading pwdles_main_heading">
        Enter one-time passcode
      </h1>
      <div
        class="new_pwdless_sub_heading pwdles_sub_heading"
        v-if="selectedOtpDevice.deviceType == 'IVR'"
      >
        We’re calling
        {{ selectedOtpDevice.deviceValue }} with a passcode.
      </div>
      <div class="new_pwdless_sub_heading pwdles_sub_heading" v-else>
        Enter the passcode that was sent to
        {{ selectedOtpDevice.deviceValue }}
      </div>
      <div class="input_container strength_label">
        <input
          class="new_pwdless_input_field sqa_input customize_dots_character"
          id="otpCode"
          name="otpCode"
          ref="otpRef"
          v-model="otpCode"
          type="text"
          required
          style="background-color: none !important"
          inputmode="numeric"
          autocomplete="one-time-code"
          :tabindex="devices.length + 4"
          maxlength="6"
          pattern="[0-9]*"
          @input="otpvalidation"
          autofocus
        />
        <span id="otpinputErrormsg" class="error_msg_style"> </span>
        <!-- <span
                      class="toggle_text new_pwdless_remember_me_text"
                      @click="togglePdVisibility"
                      >{{
                        pd_input !== ""
                          ? pdFieldType === "password"
                            ? "Show"
                            : "Hide"
                          : ""
                      }}</span
                    > -->
      </div>
      <div class="new_pwdless_btn_section">
        <button
          class="new_pwdless_submit_btn"
          type="submit"
          :class="continueDisabled ? 'disabled' : ''"
          :disabled="continueDisabled"
        >
          Continue
        </button>
      </div>
    </form>
    <div class="new_pwdless_sub_heading pwdles_sub_resend_text">
      <button
        :disabled="isButtonDisabled || attemptCount >= 3"
        @click="resendClick()"
        :class="[
          !timeLeft ? 'boldFnt' : '',
          attemptCount == 3 ? 'disableFont' : '',
        ]"
      >
        <span v-if="selectedOtpDevice.deviceType == 'IVR'">Try again</span>
        <span v-else class="resend_attempts">
          Resend
          <span
            v-if="selectedOtpDevice.deviceType == 'EMAIL'"
            class="aligning_validate_timer"
            >email</span
          >
          <span v-else class="aligning_validate_timer">text message</span>
        </span>
      </button>
      <span v-if="timeLeft && attemptCount < 3">
        in {{ timeLeft }} seconds</span
      >
      (Attempt {{ attemptCount }}/3)
    </div>
    <div
      class="new_pwdless_sub_heading"
      v-if="selectedOtpDevice.deviceType == 'EMAIL'"
    >
      <span v-if="getWhichRealm">
        <a class="link_text" href="" @click.prevent="openModal"
          >Having trouble?</a
        >
      </span>
      <div
        v-if="showModal"
        class="new_modal_overlay"
        @click="closeModal_new_modal"
      >
        <div class="new_modal_content" @click.stop>
          <div class="new_close_icon" @click.prevent="closeModal_new_modal">
            <img
              src="@/common/assets/images/close_button.png"
              alt="notification icon"
            />
          </div>
          <div class="new_popup_content">
            <div class="new_pwdless_main_heading popup_heading_gap">
              Didn’t receive an email?
            </div>
            <div class="popup_sub_heading">Check your spam folder</div>
            <div class="new_popup_text">
              Sometimes, these emails can land in your junk folder. If this is the case, be sure to mark these as “Not Spam” so it doesn’t happen again. Keep in mind, can take up to 2 minutes for your verification email to arrive.
            </div>
            <div class="new_pwdless_btn_section">
              <button
                @click.prevent="closeModal_new_modal"
                class="new_pwdless_submit_btn_popup"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- <span v-if="getWhichRealm">
        <a class="new_pwdless_forgot_text_link" @click="goBack()">
          Verify another way
        </a>
      </span> -->
    </div>
    <!-- Polaris Popup -->
    <div>
      <div id="showSdarPopup" class="modal fade">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content sdar_popup">
            <ConfirmSecurityDetailsOrDoLaterPopUp
              @completeSecurityProfile="completeSecurityProfile"
              @doitLater="doitLater"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import commonMixin from "@/common/mixin/commonMixins";
import commonErrorMixin from "../common/common-error-mixin";
import translations from "@/common/assets/json/messages1.json";
import "@/common/assets/styles/tooltip.css";
import {
  setPageDetails,
  eventTracking,
  errorSet,
} from "../../../../../public/adobeTagging";
import ConfirmSecurityDetailsOrDoLaterPopUp from "@/vbgauth/components/hybridExp/popups/ConfirmSecurityDetailsOrDoLaterPopUp";

export default {
  name: "NewOtpValidate",
  components: {
    ConfirmSecurityDetailsOrDoLaterPopUp,
  },
  mixins: [commonMixin, commonErrorMixin],
  data() {
    return {
      showModal: false,
      content:
        translations.en.message["login_verify_and_dont_register_tooltip"],
      showResendOtpLink: false,
      isInFrame: true,
      otpCode: "",
      otpValidationInputClass: "",
      // resendPasscodeToSelectedDevice: false,
      deviceTypeLabel: "",
      rememberThisDevice: false,
      url:
        iamvar_cdnUrl +
        iamvar_imgSourceContext +
        "/business/login/includes/circle-loaderWHITE_48x48.gif",
      showTokLink: false,
      timeLeft: 30,
      attemptCount: 0,
      isButtonDisabled: true,
      newMaxError: translations.en.message["new_max_verification_attempts"],
    };
  },
  mounted() {
    this.setChangedSelectedOption(true);
    setGotoUrlInClientLib(this.getParameterByName("goto"));
    this.$refs.otpRef.focus();
    if (!this.vbgFlow) {
      if (
        this.selectedOtpDevice.deviceType == "SMARTPHONE" ||
        this.selectedOtpDevice.deviceType == "EMAIL"
      ) {
        this.showTokLink = true;
      }
    }
    if (this.showTokLink) {
      this.changeContextBiocatch("pwdless_login_adeptive_auth_page");
    } else {
      this.changeContextBiocatch("pwdless_login_otp_validate_page");
    }
    if (!this.vbgFlow) {
      if (this.selectedOtpDevice.deviceType == "EMAIL") {
        this.pwdTimer(15);
      } else {
        this.pwdTimer(10);
      }
    } else {
      this.pwdTimer(10);
    }

    if (this.pwdUsername == null && !this.flowType.includes("forgot")) {
      this.$router.push({ name: "PwdLessLoginRootComponent" });
    }
    this.$store.dispatch("cvsstepup/updateOTPAlert", [], { root: true });
    this.gotoUrl = this.$route.query.goto;
    // this.rememberThisDevice = this.$route.params.rememberThisDevice;
    if (this.gotoUrl != null) {
      // determine if there is a goto with a hashtag
      let hashValue = window.location.hash.substr(1);
      if (hashValue != "") {
        let hashInUrl = "#" + window.location.hash.substr(1);
        if (this.gotoUrl != null) {
          this.gotoUrl += hashInUrl;
          this.$store.dispatch("login/updateGotoUrl", this.gotoUrl);
        }
      }
    }
    if (this.vbgFlow) {
      var deviceTypeLabelValue = this.selectedOtpDevice.label.split(" ");
      this.deviceTypeLabel =
        deviceTypeLabelValue[deviceTypeLabelValue.length - 1];
    } else if (!this.vbgFlow) {
      this.deviceTypeLabel = this.selectedOtpDevice.deviceValue;
    }
    this.observePasswordField();
    //browser back button disabled
    window.onpopstate = () => {
      window.history.forward();
    };
    this.startCountDown();
    const inputField = document.getElementById("otpCode");
    const errorMessage = document.getElementById("otpinputErrormsg");

    inputField.addEventListener("input", () => {
      if (inputField.value === "") {
        errorMessage.textContent = "Please enter the one-time passcode.";
        inputField.classList.add("error_input"); // Optional: Add a CSS class for styling
      } else {
        errorMessage.textContent = "";
        inputField.classList.remove("error_input"); // Optional: Remove the error class
      }
    });
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
    // Cancel the polling call if we click the back button
    // this.$store.dispatch("cvsstepup/cancelVerification");
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal_new_modal() {
      this.showModal = false;
    },
    goBack() {
      this.clearAllErrors();
      // Cancel the polling call if we click the back button
      if (this.getCurrentComponent.name == "NewOtpValidate")
        this.$store.dispatch("cvsstepup/cancelVerification");

      this.unsetNavHistory();
      // In forgot password flow if he entered in sqa page then back button way we are navigating to DeliveryOtp component
      if (
        this.flowType == "forgot_password" &&
        this.getCurrentComponent.name == "NewSqaComponent"
      )
        this.unsetNavHistory();
      // this is added because we have an option to verify another way in setup page
      const gotoURL = this.getParameterByNameURL("goto", this.gotoUrl);
      if (gotoURL) {
        this.$router.replace({
          path: this.getCurrentComponent.path,
          query: { goto: gotoURL },
        });
      } else {
        this.$router.replace({ path: this.getCurrentComponent.path });
      }
    },
    getMsgIcon(index) {
      // return iamvar_cdnUrl+'account/business/login/includes/img/info.png';
      return "@/common/assets/styles/corp/images/" + index.type + ".png";
    },
    messageImage(index) {
      if (
        index.type == "error" ||
        index.type == "warning" ||
        index.type == "list"
      ) {
        return "newErrorMessageImage";
      } else {
        return index.type + "MessageImage";
      }
    },
    getCloseIcon() {
      return (
        iamvar_cdnUrl +
        iamvar_imgSourceContext +
        "/business/login/includes/img/close_white.png"
      );
    },
    getAlertType(index) {
      //when error occurs take the user to the top screen where the orange banner is shown
      window.scrollTo(0, 0);
      return index.type;
    },
    deleteAlert(alert) {
      this.otpAlerts.splice(this.otpAlerts.indexOf(alert), 1);
    },
    async resendPasscode() {
      eventTracking("One Time Passcode Resend Request Initiated", "");
      let self = this;
      self.otpSent = false;
      self.showResendOtpLink = false;
      this.resendPasscodeToSelectedDevice = true;
      if (!this.vbgFlow) {
        if (
          this.selectedOtpDevice.deviceType == "EMAIL" ||
          this.resendDevice.deviceType == "EMAIL"
        ) {
          this.pwdTimer(15);
        } else {
          this.pwdTimer(10);
        }
      } else {
        this.pwdTimer(10);
      }
      this.resendOtpToDevice(this.selectedOtpDevice);
      if (!this.vbgFlow) {
        if (
          this.selectedOtpDevice.deviceType == "SMARTPHONE" ||
          this.selectedOtpDevice.deviceType == "EMAIL"
        ) {
          this.updateVerificationSent(false);
        }
      }
    },
    openUnableReceiveOverlay() {
      this.unableToReceiveOtp();
    },
    isDisabled() {
      if (!this.otpSent || !this.otpCode) {
        return "inactive";
      }
    },
    validateOtpCode(saveFingerprint) {
      this.clearAllErrors();
      if (!this.otpSent || !this.otpCode) {
        // the continue button is disabled and should not be clickable
      } else {
        eventTracking("One Time Passcode Submitted", "");
        this.showResendOtpLink = false;

        // trim spaces at the beginning and at the end of otp
        this.otpCode = this.otpCode.trim();
        if (this.$store.getters["cvsstepup/getregisterDevice"]) {
          saveFingerprint = true;
        }
        var otpValidateParams = {
          otpCode: this.otpCode,
          saveFingerprint: saveFingerprint,
        };
        this.validateOtp(otpValidateParams);
      }
    },
    pwdTimer(maxMinutes) {
      clearInterval(interval);
      let secondsRemaining = maxMinutes * 60;
      // let minutes; let seconds;
      var current_time = Date.parse(new Date());
      var endTime = new Date(current_time + maxMinutes * 60 * 1000);
      interval = setInterval(
        function (triggerInlineError) {
          var diff = Date.parse(endTime) - Date.parse(new Date());
          var seconds = 0;
          var minutes = 0;
          if (diff >= 0) {
            seconds = Math.floor((diff / 1000) % 60);
            minutes = Math.floor((diff / 1000 / 60) % 60);
          }
          minutes = minutes < 10 ? "0" + minutes : minutes;
          seconds = seconds < 10 ? "0" + seconds : seconds;
          if (document.getElementById("expiretime")) {
            document.getElementById("expiretime").innerHTML =
              minutes + ":" + seconds;
          }
          // time is up
          if (seconds === "00" && minutes === "00") {
            clearInterval(interval);
            setTimeout(function () {
              let msg =
                "The verification code you’ve entered is invalid or expired.Please try again or click Resend to get a different code.";
              this.pwdInlineError = msg;
              triggerInlineError(msg);
              //if(document.getElementById("inlineError")) {
              document.getElementById("otpCode").classList.add("pwdLessAlert");
              //document.getElementById("inlineError").setAttribute('style', 'display:flex')
              //document.getElementById("inlineError").innerHTML = "The verification code you’ve entered is invalid or expired.</b><br>Please try again or click Resend to get a different code.";
              //}
            }, 3000);
          }
          secondsRemaining--;
        },
        1000,
        this.updateInlineError
      );
    },
    cancelVerification() {
      this.$store.dispatch("cvsstepup/cancelVerification");
      this.$store.dispatch("cvsstepup/resetState");
      this.$store.dispatch("forgotflow/resetState");
      // this.$router.go(-3)
      if (this.flowType === "forgot_username")
        this.$router.push({ name: "AMForgotUsrnmComponent" }).catch((err) => {
          throw err;
        });
      else if (this.flowType === "forgot_password")
        this.$router.push({ name: "AMForgotPwdComponent" }).catch((err) => {
          throw err;
        });
    },
    dontHaveOTPAccess() {
      $("#noAccessToOtpModal").modal({
        backdrop: "static",
      });
      this.updatecantrcvLink(true);
    },
    getOAuthClass(className = "oauth-flow", subClass = "") {
      return className + " " + subClass;
    },
    // supportUrl() {
    //   if (this.gotoUrl != null) {
    //     this.$router.push({
    //       name: "PwdLessOption",
    //       query: { goto: this.gotoUrl },
    //     }); //.catch(err => {throw err});
    //   } else {
    //     this.$router.push({ name: "PwdLessOption" });
    //   }
    //   this.$store.dispatch("cvsstepup/cancelVerification");
    // },
    getHostName(url) {
      var match = url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i);
      if (
        match != null &&
        match.length > 2 &&
        typeof match[2] === "string" &&
        match[2].length > 0
      ) {
        return match[2];
      } else {
        return null;
      }
    },
    continueButtonChange() {
      Vue.nextTick(() => {});
    },
    ...mapActions("cvsstepup", [
      "validateOtp",
      "unableToReceiveOtp",
      "sendOtpToDevice",
      "resendOtpToDevice",
      "updatecantrcvLink",
      "updateOTPAlwaysOn",
      "updateVerificationSent",
      "initializeStepup",
      "updateSelectedDevice",
      "updateOTPAlert",
      "updateShowChangeError",
      "updateInlineError",
    ]),
    ...mapActions("forgotflow", [
      "updateUserVerified",
      "setFFTransactionId",
      "setFFUsername",
      "getUsername",
    ]),
    ...mapActions("login", ["redirectToOpenam", "updateRspToken"]),
    ...mapMutations("login", ["setNavHistory", "unsetNavHistory"]),
    ...mapMutations("cvsstepup", ["setChangedSelectedOption"]),
    // gotoPwdLessLogin() {
    //   if (this.gotoUrl != null) {
    //     this.$router.push({
    //       name: "PwdLessLoginRootComponent",
    //       query: { goto: this.gotoUrl },
    //     }); //.catch(err => {throw err});
    //   } else {
    //     this.$router.push({ name: "PwdLessLoginRootComponent" });
    //   }
    //   this.$store.dispatch("cvsstepup/cancelVerification");
    // },
    closeModal() {
      $("#noAccessToOtpModal").modal("hide");
    },
    // gotoPwdLessOtpDelivery() {
    //   this.cancelVerification();
    //   this.initializeStepup();
    //   this.updateShowChangeError(true);
    //   this.gotoUrl
    //     ? this.$router.push({
    //         name: "PwdLessOtpDelivery",
    //         query: { goto: this.gotoUrl },
    //       })
    //     : this.$router.push({ name: "PwdLessOtpDelivery" });
    // },
    // gotoPwdLessOtpDeliveryFromTok() {
    //   if (!this.vbgFlow) {
    //     this.updatecantrcvLink(false);
    //     this.updateShowChangeError(true);
    //     this.updateVerificationSent(true);
    //     this.updateSelectedDevice(this.defaultSelectedDevice);
    //   }
    //   this.updateOTPAlert([]);
    //   this.cancelVerification();
    //   this.initializeStepup();
    //   this.gotoUrl
    //     ? this.$router.push({
    //         name: "PwdLessOtpDelivery",
    //         query: { goto: this.gotoUrl },
    //       })
    //     : this.$router.push({ name: "PwdLessOtpDelivery" });
    // },
    showSdarPopup() {
      this.$store.dispatch("login/updateDisplayPopupClass", true);
      $("#showSdarPopup").modal({
        backdrop: "static",
      });
    },
    completeSecurityProfile() {
      $("#showSdarPopup").modal("hide");
      // sending user selection option to AM
      setSelectedValueInClientLibForCreatingSession("1");
      // redirecting to AM for logging in the user
      this.redirectToOpenam(this.sdarUserType);
    },
    doitLater() {
      // sending user selection option to AM
      setSelectedValueInClientLibForCreatingSession("2");
      // redirecting to AM for logging in the user
      this.redirectToOpenam(this.sdarUserType);
    },
    observePasswordField() {
      const passwordField = this.$refs.otpRef;
      //added MutationObserver to check if 'type' attrbute of input feild has changed
      this.observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (
            mutation.attributeName === "type" &&
            passwordField.type !== "text"
          ) {
            passwordField.type = "text";
          }
        });
      });
      const config = { attributes: true };
      this.observer.observe(passwordField, config);

      passwordField.addEventListener("input", () => {
        if (passwordField.type !== "text") {
          passwordField.type = "text";
        }
      });
    },
    otpvalidation() {
      this.otpCode = this.otpCode.replace(/\D/g, "");
    },
    observePasswordField() {
      const passwordField = this.$refs.otpRef;
      //added MutationObserver to check if 'type' attrbute of input feild has changed
      this.observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (
            mutation.attributeName === "type" &&
            passwordField.type !== "text"
          ) {
            passwordField.type = "text";
          }
        });
      });
      const config = { attributes: true };
      this.observer.observe(passwordField, config);

      passwordField.addEventListener("input", () => {
        if (passwordField.type !== "text") {
          passwordField.type = "text";
        }
      });
    },
    startCountDown() {
      const timer = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft--;
        } else {
          this.isButtonDisabled = false;
          clearInterval(timer);
        }
      }, 1000);
    },
    resendClick() {
      this.otpCode = "";
      if (this.attemptCount < 3) {
        this.attemptCount++;
        this.isButtonDisabled = true;
        this.timeLeft = 30;
        this.startCountDown();
        this.resendPasscode();
        if (this.attemptCount == 3) {
          this.$store.dispatch(
            "cvsstepup/updateOTPAlert",
            [{ msg: this.newMaxError, type: "error" }],
            { root: true }
          );
        }
      } 
    },
  },
  computed: {
    ...mapGetters("cvsstepup", [
      "dynamicErrorClasses",
      "fingerprintSaved",
      "sqaDisabled",
      "isPwReset",
      "alerts",
      "dialogMsg",
      "dialogTitle",
      "submitLoader",
      "currentStep",
      "transactionId",
      "username",
      "flowType",
      "email",
      "vbgFlow",
      "otpValidationError",
      "selectedOtpDevice",
      "otpDeliveryMsg",
      "otpAlwaysOn",
      "urlOnMount",
      "cantrcvLink",
      "devices",
      "flowInitialized",
      "stepupReason",
      "urlOnMount",
      "otpAlwaysOn",
      "dnrStatus",
      "defaultSelectedDevice",
      "resendDevice",
    ]),
    ...mapGetters("profile", ["sdarUserType"]),
    ...mapGetters("login", ["getCurrentComponent", "gotoUrl"]),
    otpSent: {
      get: function () {
        return this.$store.getters["cvsstepup/otpSent"];
      },
      set: function (newVal) {
        this.$store.dispatch("cvsstepup/updateOtpSent", newVal);
      },
    },
    pwdInlineError: {
      get: function () {
        return this.$store.getters["cvsstepup/pwdInlineError"];
      },
      set: function (newVal) {
        this.$store.commit("cvsstepup/setPwdInlineError", newVal);
      },
    },
    resendVerificationToSelectedDevice: {
      get: function () {
        return this.$store.getters[
          "cvsstepup/resendVerificationToSelectedDevice"
        ];
      },
      set: function (newVal) {
        this.$store.dispatch(
          "cvsstepup/updateResendVerificationToSelectedDevice",
          newVal
        );
      },
    },
    otpAlerts: {
      get: function () {
        return this.$store.getters["cvsstepup/otpAlerts"];
      },
      set: function (newVal) {
        this.$store.commit("cvsstepup/setOTPAlert", newVal);
      },
    },
    dialog: {
      get: function () {
        return this.$store.getters["cvsstepup/dialog"];
      },
      set: function (newVal) {
        this.$store.dispatch("cvsstepup/updateShowDialog", newVal);
      },
    },
    resendPasscodeToSelectedDevice: {
      get: function () {
        return this.$store.getters["cvsstepup/resendPasscodeToSelectedDevice"];
      },
      set: function (newVal) {
        this.$store.dispatch(
          "cvsstepup/updateResendPasscodeToSelectedDevice",
          newVal
        );
      },
    },
    ...mapGetters("login", ["gotoUrl", "pwdUsername"]),
    formData: {
      get: function () {
        return this.$store.getters["login/formData"];
      },
      set: function (newVal) {
        this.$store.dispatch("login/updateFormData", newVal);
      },
    },
    pwdAuthOptions: {
      get: function () {
        return this.$store.getters["login/pwdAuthOptions"];
      },
    },
    gotoUrl: {
      get: function () {
        return this.$store.getters["login/gotoUrl"];
      },
      set: function (newVal) {
        this.$store.dispatch("login/updateGotoUrl", newVal);
        this.$store.dispatch("login/updateEncodedGotoUrl", newVal);
      },
    },
    continueDisabled() {
      return !this.otpSent || !this.otpCode;
    },
  },
  watch: {
    otpSent: function (newVal) {
      if (newVal) {
        // this.$router.push({ path: 'send' })
      }
    },
    dialogMsg: function (newVal) {},
    otpValidationError: function (newVal) {
      if (newVal) {
        this.otpValidationInputClass = "vz-error";
        this.otpCode = "";
      }
    },
    resendVerificationToSelectedDevice: function (newVal) {
      if (newVal) {
        if (
          !this.vbgFlow &&
          (this.flowType == "forgot_password" ||
            this.flowType === "forgot_username" ||
            this.flowType == "auth")
        ) {
          this.updatecantrcvLink(false);
          this.$store.dispatch("forgotflow/updateVbgFlow", false);
          this.resendVerificationToSelectedDevice = false;
        }
      }
    },
    otpAlerts: function (newVal) {
      console.log("newVal for otpAlerts", newVal);
      if (newVal) {
        if (newVal.length > 0) {
          const hasError = newVal.some((res) => res.type == "error");
          if (hasError) {
            this.pwdTimer(0);
          }
        }
      }
    },
    currentStep: function (newVal) {
      console.log("otpvalidate-currenstep", newVal, this.flowType);
      if (newVal === "OTP_VALIDATED" && this.flowType === "forgot_password") {
        this.updateUserVerified(true);
        this.setFFTransactionId(this.transactionId);
        this.setFFUsername(this.username);
        if (pwd_less_new_login) {
          if (this.gotoUrl) {
            this.$router.push({
              name: "NewAMResetPasswordComponent",
              query: { goto: this.gotoUrl },
            });
          } else this.$router.push({ name: "NewAMResetPasswordComponent" });
        } else this.$router.push({ name: "AMForgotPwdComponent" });
      } else if (
        newVal === "OTP_VALIDATED" &&
        this.flowType === "forgot_username"
      ) {
        this.updateUserVerified(true);
        this.setFFTransactionId(this.transactionId);
        this.getUsername(this.email);
        // redirect user back to login page
        this.redirectBackToLogin(this.urlOnMount, this.gotoUrl);
      }
    },
    fingerprintSaved: function (newVal) {
      if (newVal) {
        if (this.flowType === "auth") {
          if (iamvar_polarisFlowToggle && this.sdarUserType) {
            this.showSdarPopup();
          } else {
            this.redirectToOpenam();
          }
        } else if (
          iamvar_loginAssistant &&
          (this.flowType === "login_assistant" || this.flowType === "fp_proactive_flow") && 
          this.currentStep === "OTP_VALIDATED"
        ) {
          // for login assistant we need to call a IAM api to receive a b2b token to redirect the user on RSP pages
          this.updateRspToken();
        } else if (
          this.currentStep === "OTP_VALIDATED" &&
          this.continueUrl !== null
        ) {
          // window.location.href = this.continueUrl

          // append needed query parameter if continuing to profile flow
          if (this.continueUrl.includes("/profile/postauth")) {
            // split the whole url after the question mark; if something is present after the question mark, parameters have been found
            // append query parameter as & if parameters are found, or append it with a '?' otherwise
            let qParameterCheck = this.continueUrl.split("?");

            if (qParameterCheck[1] !== "") {
              // check split after '?'
              // append new parameter using '&'
              window.location.href =
                this.continueUrl + "&stepupVerification=true";
            } else {
              // append new parameter using '?'
              window.location.href =
                this.continueUrl + "?stepupVerification=true";
            }
          } else window.location.href = this.continueUrl;
        }
      }
    },
  },
};
</script>

<style scoped>
.pwdless_pass_forgot_text,
.back_icon_div {
  margin-bottom: 24px;
}
.pwdles_pass_sub_heading {
  margin-bottom: 4px;
}
.pwdless_pass_forgot_text {
  text-align: right;
}
.input_container {
  position: relative;
}
.toggle_text {
  position: absolute;
  right: 12px;
  top: 11.5px;
  border-bottom: 1px solid #000000;
  padding-bottom: 2px;
  cursor: pointer;
}
.pwdles_pass_main_heading {
  margin-bottom: 24px;
}
.pwdless_pass_user_gap {
  margin-bottom: 32px;
}
.pwdles_main_heading {
  margin-bottom: 12px;
}
.pwdles_sub_resend_text {
  margin-top: 64px;
}
.sdar_popup {
  padding: 30px 15px !important;
  height: 400px !important;
}
.pwdless_pass_forgot_text,
.back_icon_div {
  margin-bottom: 24px;
}
.pwdles_pass_sub_heading {
  margin-bottom: 4px;
}
.pwdless_pass_forgot_text {
  text-align: right;
}
.input_container {
  position: relative;
}
.toggle_text {
  position: absolute;
  right: 12px;
  top: 11.5px;
  border-bottom: 1px solid #000000;
  padding-bottom: 2px;
  cursor: pointer;
}
.pwdles_pass_main_heading {
  margin-bottom: 24px;
}
.pwdless_pass_user_gap {
  margin-bottom: 32px;
}
.pwdles_main_heading {
  margin-bottom: 24px;
}
.pwdles_sub_resend_text {
  margin-top: 48px;
  margin-bottom: 16px;
}
.sqa_input {
  margin-bottom: 0;
}
.pwdles_main_heading {
  margin-bottom: 12px;
}
.pwdles_sub_heading {
  margin-bottom: 44px;
}
.disabled {
  background: #d8dada !important;
}
@media (max-width: 540px) {
  .sdar_popup {
    height: 100% !important;
    margin: auto !important;
    width: 100% !important;
    border-radius: 0px !important;
  }
}
.boldFnt {
  text-decoration: none;
  border-bottom: 1px solid #000000;
  /* padding-bottom: 1px; */
  font-weight: normal;
}
.new_close_icon {
  float: right;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  cursor: pointer;
}
.new_popup_content {
  padding: 48px;
}
.popup_heading_gap {
  margin-bottom: 32px;
}
.new_pwdless_submit_btn_popup {
  background-color: #000000;
  color: #ffffff;
  padding: 12px 24px;
  border-radius: 24px;
  font-family: "VzNHGeDS-bold11";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.5px;
  width: 92px;
}
.new_modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.new_modal_content {
  background-color: #fff;
  /* padding: 20px; */
  border-radius: 8px;
  max-width: 560px;
  width: 100%;
  max-height: 300px;
}
.popup_sub_heading {
  color: #000000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.5px;
  font-family: "VzNHGeDS-bold11";
  text-align: left;
  margin-bottom: 4px;
}
.new_popup_text {
  color: #000000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.5px;
  font-family: "VzNHGeDS-reg11";
  text-align: left;
}
.disableFont {
  cursor: not-allowed;
  color: #959595 !important;
  text-decoration: none;
}
.resend_attempts {
  position: relative;
  top: 0px !important;
}
@media only screen and (max-width: 1023px) {
  .new_popup_content {
    padding: 48px 16px;
  }
  .new_modal_content {
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;
    border-radius: 0;
  }
  .new_pwdless_submit_btn {
    width: 100%;
  }
  .new_pwdless_submit_btn_popup {
    width: 100%;
  }
}
.aligning_validate_timer {
  position: relative;
  top: 0px;
}
.link_text {
  text-decoration: none;
  border-bottom: 1px solid #000000;
  font-weight: normal;
}
.customize_dots_character{
  font-family: 'password';
  -webkit-text-security:disc;
}
.error_input {
  border: 1px solid #B95319 !important;
  background-color: #FFECE0;
}
.error_msg_style {
  font-size: 14px;
  font-weight: bold;
}
</style>
